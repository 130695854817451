import { ProductFilter } from "@startapp/laira-user-ssr-api";

export function selectSearchParams(filter: ProductFilter) {
	const sectionTags: string[] = filter
		? filter.tags.map((tag) => tag.id)
		: [];
	const category: string =
		filter && filter.category ? filter.category : "all";

	return {
		pathname: "/search/" + category,
		query: {
			page: 0,
			t: sectionTags,
			storeId: filter.storeId,
		},
	};
}
