import React from "react";
import { observer } from "mobx-react-lite";
import { NextPage, GetServerSideProps } from "next";
import { Container, Image } from "@chakra-ui/react";
import * as api from "@startapp/laira-user-ssr-api";

import { PageContainer } from "components/general/PageContainer";
import { Banner } from "components/general/Banner";
import { Footer } from "components/general/Footer";
import { AppBar } from "components/general/AppBar";
import { CategoriesBar } from "components/general/CategoriesBar";
import { parseProps } from "resources/parseProps";
import { useScrollToTop } from "hooks/useScrollToTop";
import { ProductSampleSection } from "components/domain/ProductSampleSection";

interface IProps {
	homeData: api.HomeData;
}

type GSProps = GetServerSideProps<IProps>;
type Page = NextPage<IProps>;

export const getServerSideProps: GSProps = async () => {
	try {
		const homeData = await api.getHomeData();
		return { props: parseProps({ homeData }) };
	} catch (err) {
		return { notFound: true };
	}
};

const IndexPage: Page = observer((props) => {
	useScrollToTop([]);
	const { homeData } = props;

	return (
		<PageContainer>
			<AppBar hasSearchBar />
			{homeData.banners && <Banner banners={homeData.banners} />}
			<CategoriesBar />
			<Container padding="0" maxWidth="6xl">
				{homeData.sections.map((section) => (
					<ProductSampleSection key={section.id} section={section} />
					))}
			</Container>
			<Container p={0} maxWidth="6xl">
				<Image
					mt={10}
					src="./laira-desktop.webp"
					alt="Sobre nós"
					display={{ base: "none", md: "block" }}
				/>
				<Image
					mt={10}
					src="./laira-mobile.webp"
					alt="Sobre nós"
					display={{ base: "block", md: "none" }}
				/>
			</Container>
			<Footer />
		</PageContainer>
	);
});

export default IndexPage;
