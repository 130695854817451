import React from "react";
import { Box, Flex, Heading, HStack, VStack } from "@chakra-ui/react";
import * as api from "@startapp/laira-user-ssr-api";

import Strings from "resources/Strings";
import { SimpleProductCard } from "components/domain/SimpleProductCard";
import { Link } from "components/general/Link";
import { selectSearchParams } from "resources/filterToHref";

interface IProps {
	section: api.HomeSection;
}

const numOfProducts = 4;

export function ProductSampleSection(props: IProps) {
	const { section } = props;
	return (
		<VStack
			key={section.id}
			marginTop={10}
			paddingLeft={2}
			paddingRight={2}
		>
			<HStack
				marginBottom={5}
				width="100%"
				justifyContent="space-between"
			>
				<Heading
					letterSpacing="wide"
					fontSize={{ base: "md", lg: "lg" }}
					fontWeight="300"
					color="gray.700"
				>
					{section.name.toUpperCase()}
				</Heading>
				<Link
					href={selectSearchParams(section.filter) as any}
					fontWeight="500"
					fontSize="sm"
					color="green.900"
				>
					{Strings.general.seeMore}
				</Link>
			</HStack>
			<Flex
				width="100%"
				sx={{
					WebkitOverflowScrolling: "touch",
					"::-webkit-scrollbar": {
						display: "none",
					},
				}}
				overflowX="auto"
				d="flex"
				flexWrap="wrap"
				justifyContent="space-around"
				columns={{ base: 2, md: 4, lg: 4 }}
				spacing={8}
			>
				{section.products
					.slice(0, numOfProducts)
					.map((product) => (
						<Box
							key={product.id}
							m={1}
							boxSizing="content-box"
						>
							<SimpleProductCard
								marginBottom={4}
								key={product.id}
								product={product}
							/>
						</Box>
					))}
			</Flex>
		</VStack>
	);
}
