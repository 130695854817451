import React from "react";

// MARK: Libs
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Box } from "@chakra-ui/react";
// MARK: Api
import * as api from "@startapp/laira-user-ssr-api";
import BannerImage from "./bannerImage";

interface IProps {
	banners: api.Banner[];
}

export const Banner: React.FC<IProps> = (props) => {
	const { banners } = props;

	return (
		<Box height="auto">
			<Carousel
				interval={10000}
				infiniteLoop
				autoPlay
				showStatus={false}
				showThumbs={false}
				showArrows={false}
			>
				{banners.map((banner) => (
					<BannerImage key={banner.id} banner={banner} />
				))}
			</Carousel>
		</Box>
	);
};
