import React, { useEffect, useState } from "react";

// MARK: api
import * as api from "@startapp/laira-user-ssr-api";

// MARK: Libs
import Image from "next/image";
import { Box } from "@chakra-ui/react";
import { useBreakpointValue } from "@chakra-ui/react";
import { useRouter } from "next/router";

interface IProps {
	banner: api.Banner;
}

const BannerImage: React.FC<IProps> = ({ banner }) => {
	const variantDesktop = useBreakpointValue({ base: "none", lg: "block" });
	const variantMobile = useBreakpointValue({ base: "block", lg: "none" });

	const router = useRouter();

	const onGoToBannerLink = () => {
		router.push(banner.url);
	};

	return (
		<>
			<Box display={variantDesktop} width="100%"  cursor="pointer" onClick={() => onGoToBannerLink()} >
				<Image
					loading="eager"
					layout="responsive"
					priority={true}
					key={banner.id}
					quality={100}
					width={banner.image.width}
					height={banner.image.height}
					src={banner.image.url}
					alt={banner.title}
				/>
			</Box>
			<Box display={variantMobile} width="100%" cursor="pointer" onClick={() => onGoToBannerLink()} >
				<Image
					loading="eager"
					layout="responsive"
					priority={true}
					key={banner.id}
					quality={100}
					width={banner.mobileImage.width}
					height={banner.mobileImage.height}
					src={banner.mobileImage.url}
					alt={banner.title}
				/>
			</Box>
		</>
	);
};

export default BannerImage;
